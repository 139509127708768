<template>
  <MainLayout>
    <template v-slot:navbar-title>{{
      sourceObject ? sourceObject.code : ""
    }}</template>

    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <BasicTableWithoutHeader :table-data="getDataRequisites">
          <template #item.1.value="{}">
            <div class="d-flex ">
              <ValidationInputField
                class="mr-4 d-flex "
                mask="###.#####"
                :disabled="!isEdit"
                v-model="code"
              />

              <div class="">
                <template>
                  <v-btn
                    type="submit"
                    @click="isEdit = !isEdit"
                    class="btn btn-green "
                  >
                    Изменить
                  </v-btn>
                </template>
              </div>

              <div class="ml-auto">
                <template>
                  <v-btn
                    type="submit"
                    @click="editCard"
                    class="btn btn-green "
                    :disabled="code?.length != 9 || !isEdit"
                  >
                    Сохранить
                  </v-btn>
                </template>
              </div>
            </div>
          </template>
          <template #item.2.value="{}">
            <div class="d-flex justify-space-between">
              <div class="align-self-center mr-4 d-flex">{{ status }}</div>

              <unLockPopup
                v-if="
                  sourceObject.status == 'lost' ||
                    (currentRole == 60 && currentRole == 70)
                "
                name="Карту"
                :title="sourceObject ? sourceObject.code : ''"
                :un-block-function="clickRestore"
              />
            </div>
          </template>
          <template #item.3.value="{item}">
            <a
              v-if="item.value != 'Отсутствует'"
              :href="'#/payment/' + item.value"
              :style="
                item.value == 'Отсутствует'
                  ? 'color: #E5667F !important; '
                  : 'color: #2067b0 !important; cursor: pointer'
              "
            >
              {{ item.value }}
            </a>
          </template>
        </BasicTableWithoutHeader>
      </template>

      <template v-slot:pass_log><kardsPassageLog /></template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import unLockPopup from "@/components/popup/unLockPopup";
import kardsPassageLog from "@/components/info/users/kardsPassageLog";

import * as query from "@/helpers/query";
import moment from "moment/moment";
import phoneFilter from "@/helpers/filters/phoneFilter";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import { getIdentificatorUserRole } from "../../helpers/Constants/roles";
export default {
  name: "CardsInfosPages",
  components: {
    SlideLayout,
    MainLayout,
    BasicTableWithoutHeader,
    ValidationInputField,
    unLockPopup,
    kardsPassageLog
  },
  data: () => ({
    type: "cards",
    queries: query.organizations,
    isEdit: false,
    slideTabs: [
      {
        text: "Общая информация",
        value: "info",
        icon: "i"
      },
      {
        text: "Журнал проходов",
        value: "pass_log",
        icon: null,
        count: null
      }
    ]
  }),
  methods: {
    clickRestore() {
      this.isDateLoaded = true;
      this.$store
        .dispatch("restoreCard", { id: this.$store.getters.getPageQuery.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.restore(successMessageDictionary.card)
          );
        })
        .then(() => {
          this.$store.dispatch("fetchPageCardsIdList");
        })
        .finally(() => {
          this.isDateLoaded = false;
        });
    },
    editCard() {
      this.loading = true;
      this.$store
        .dispatch("editCard", {
          id: this.$route.params.id,
          kinderId: getIdentificatorUserRole()
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.card)
          );
        })
        .finally(() => (this.loading = false));
    }
  },
  computed: {
    code: {
      get() {
        return this.$store.getters.getPageCardsList?.code;
      },
      set(newValue) {
        this.$store.commit("changeEditCards", {
          fieldName: "code",
          value: newValue
        });
      }
    },
    full_name: {
      get() {
        return this.$store.getters.getPageCardsList?.user_details?.full_name;
      }
    },
    status: {
      get() {
        return this.$store.getters.getPageCardsList?.status_display;
      }
    },
    sourceObject() {
      return this.$store.getters.getPageCardsList;
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    getDataRequisites() {
      return [
        {
          text: "ID",
          value: this.sourceObject.id
        },
        {
          text: "Номер",
          value: this.sourceObject.code
        },
        {
          text: "Статус",
          value: this.sourceObject?.status_display
        },
        {
          text: "Оплата №",
          value: this.sourceObject?.payment_id
            ? this.sourceObject?.payment_id
            : "Отсутствует"
        },
        {
          text: "Дата создания",
          value: moment(this.sourceObject?.created_at * 1000).format(
            "DD.MM.YYYY"
          )
        },

        {
          text: "Дата окончания срока действия",
          value:
            this.sourceObject.active_until &&
            moment(this.sourceObject.active_until * 1000).isValid()
              ? moment(this.sourceObject.active_until * 1000).format(
                  "DD.MM.YYYY"
                )
              : "-"
        },

        {
          text: "Активировать до",
          value:
            this.sourceObject.activate_until &&
            moment(this.sourceObject.activate_until * 1000).isValid()
              ? moment(this.sourceObject.activate_until * 1000).format(
                  "DD.MM.YYYY"
                )
              : "-"
        },
        {
          text: "Дата активации",
          value:
            this.sourceObject.activated_at &&
            moment(this.sourceObject.activated_at * 1000).isValid()
              ? moment(this.sourceObject.activated_at * 1000).format(
                  "DD.MM.YYYY"
                )
              : "-"
        },
        // {
        //   text: "Пользователь ",
        //   value: this.sourceObject?.user_details?.full_name || "-"
        // },
        {
          text: "Телефон",
          value:
            this.sourceObject.phone_number != null
              ? phoneFilter.execute(this.sourceObject?.phone_number)
              : "-"
        },
        {
          text: "Объект",
          value: this.sourceObject?.organization_name || "-"
        },
        {
          text: "Группа пользователей",
          value: this.sourceObject?.group_details?.title || "-"
        },
        {
          text: "Срок  действия",
          value:
            this.sourceObject.validity && this.sourceObject.validity == 1200
              ? "Бессрочно"
              : this.sourceObject.validity
              ? this.sourceObject.validity + " мес."
              : "-"
        }
      ];
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  created() {
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, type: this.type }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageCardsIdList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageCardsIdList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearOrganizationPage").then(() => next());
  }
};
</script>

<style scoped></style>
